<template>
  <div>
    <Nav />

    <Tittle :type="'general'">
      <h1 class="fadeInUp">
        FASLEARN AFFILIATE MARKETING PARTNERSHIP
      </h1>
    </Tittle>

    <div>
      <div class="container">
        <br /><br />
        <h4 style="text-align:center; font-size:30px">
          Make money online - Become a
          <strong>FASLEARN AFFILIATE MARKETING PARTNER</strong> and earn over
          100,000 naira and above monthly.
          
        </h4>
        <div class="container my-5">
            <div class="row ">
              <img src="./assets/img/flyer.jpeg" width="700" class="img-thumbnail mx-auto img-fluid rounded">  
            </div>
        </div>
  
        <p>
          Join thousands of other smart Africans who are making legitimate money
          online selling FASLEARN (Africa’s number one online learning platform)
          e-learning products and services.
        </p>
        <p>
          This works for anybody, regardless of your experience, profession,
          background, financial situation, who you are, where you live, and so
          on. You can make extra cash as you work from home or the office.
          Anybody can be successful as a FASLEARN affiliate.
        </p>
        <p>
          The idea is easy and straightforward, you include your links to our
          products and services and get as much as 10% when your visitors make a
          purchase through your link.
        </p>
        <p>
          Become an affiliate marketer today and change your financial status.
          You owe it to yourself to be financially free!
        </p>
        <p>
          You pay nothing to join, it's free forever! You sell at your time and
          pace.
        </p>
        <p>
          Hurry now! People are legally making huge amounts of money weekly
          using their smartphones.
        </p>
        <p>
          Whether as a professional, student, employee, or entrepreneur, this
          affiliate program is for you. You can earn side cash being active with
          us.
        </p>
        <p>
          Watch this video, it has all the questions you need on how to
          <a href="https://faslearn.com/register">sign up</a> .
        </p>

        <mdb-container>
          <div class="embed-responsive embed-responsive-21by9">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/i6i22afak0g"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </mdb-container>
        <div class="container mt-5">
          <div class="row">
            <h3>FAQ</h3>
          </div>
          <div class="row justify-content-center">
            <div class="col-lg-8">
              <div class="tab-content faq_content Cus_acc" id="myTabContent">
                <div
                  class="tab-pane fade show active"
                  id="course"
                  role="tabpanel"
                  aria-labelledby="course-tab"
                >
                  <div id="accordion">
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            How much can I make as an affiliate?<i
                              class="ti-plus"
                            ></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>

                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          We offer commission rates on every course sold. The
                          more you promote, the more you earn.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingTwo">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            What do I promote? <i class="ti-plus"></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseTwo"
                        class="collapse"
                        aria-labelledby="headingTwo"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          You're to promote courses on our platform.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingThree">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link collapsed"
                            data-toggle="collapse"
                            data-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            How do I get promotional materials?
                            <i class="ti-plus"></i><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseThree"
                        class="collapse"
                        aria-labelledby="headingThree"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          You can access promotional materials and content
                          across our social media pages.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Do I have access to special discounts?<i
                              class="ti-plus"
                            ></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Yes, when you hit a certain milestone, you will have
                          access to Discounted or free courses.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            When do I get paid? <i class="ti-plus"></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          You get paid monthly once upon request.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Do I have access to special discounts?<i
                              class="ti-plus"
                            ></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Yes, when you hit a certain milestone, you will have
                          access to Discounted or free courses.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            What fees do I have to pay?<i class="ti-plus"></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Becoming an affiliate is completely free.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Where can I promote your courses?
                            <i class="ti-plus"></i><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Anywhere including online and physical.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            How do I get paid?<i class="ti-plus"></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          On <a href="https://faslearn.com/register">sign up</a>, you will be required to add your account
                          details, once payment is due, you will receive a
                          deposit to your account.
                        </div>
                      </div>
                    </div>
                    <div class="card">
                      <div class="card-header" id="headingOne">
                        <h5 class="mb-0">
                          <button
                            class="btn btn-link"
                            data-toggle="collapse"
                            data-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Can I monitor my sales? <i class="ti-plus"></i
                            ><i class="ti-minus"></i>
                          </button>
                        </h5>
                      </div>
                      <div
                        id="collapseOne"
                        class="collapse show"
                        aria-labelledby="headingOne"
                        data-parent="#accordion"
                      >
                        <div class="card-body">
                          Yes, you would be able to see purchases via your
                          dashboard.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
             
          </div>
          <div  >
                   <router-link to="/register">
        <div class="container">
          <p class="btn_home_align">
            <a href="" class="btn_1 rounded" style="background-color: #ef6421"
              >SIGN UP NOW</a
            >
          </p>
          
        </div>
      </router-link>
      <p>Got further questions? join our <a href="https://t.me/faslearnaffiliates">telegram group</a> </p>
                </div>
        </div>
      </div>
    </div>
    <!--/contact_info-->
    <br /><br />
    <Footer />
  </div>
</template>

<script>
import Nav from "@/components/site/Nav.vue";
import Tittle from "@/components/site/Tittle.vue";
import Footer from "@/components/site/Footer.vue";
export default {
  name: "Contact",
  components: {
    Nav,
    Tittle,
    Footer,
  },
  mounted() {
    let js = document.createElement("script");
    js.setAttribute("src", "js/main.js");
    document.head.appendChild(js);
    let jsc = document.createElement("script");
    jsc.setAttribute("src", "js/common_scripts.js");
    document.head.appendChild(jsc);
  },
};
</script>

<style>
</style>